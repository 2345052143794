// ------------- REACT -------------
import React, { useState } from "react";

// ------------- MUI -------------
import {
    withStyles,
    Button,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableContainer,
    TableRow,
} from "@material-ui/core";

import NumberFormat from "react-number-format";
import { useReactToPrint } from "react-to-print";

// ------------- CSS -------------
import "./FlighInfoBanner.css";

// ------------- COMPONENTS -------------
import FlightRouteTooltipCell from "./FlightRouteTooltipCell";
import { TextField } from "@mui/material";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#180aa2",
        color: theme.palette.common.white,
        fontWeight: "bold",
        fontSize: 16,
        borderBottomColor: theme.palette.common.black,
    },
    body: {
        fontSize: "1rem",
    },
}))(TableCell);

export default function FlightInfoBanner({
    selectedFlightInfo,
    GreatCircleRef,
    componentRef,
    handleEmailChange,
    handleCancelEmail,
    handleSendEmail,
    handleEmail,
    email,
    isValidEmail,
    open,
}) {
    // ------------- DESTRUCTURING PROPS -------------
    const {
        flightRef,
        tailNumber,
        flightNumber,
        aircraftModel,
        arrival,
        departure,
        eta,
        etd,
        atd,
        ata,
        mtow,
        atcCosts,
        totalCosts,
        legacy_format,
    } = selectedFlightInfo;

    // TODO: when functionality is completed for user selecting weight type, it will be pass in as a prop and can access correct property
    const [weightType, setWeightType] = useState("LB");
    const weight = mtow ? mtow[`MAX_TOW_${weightType}`] : "";

    const handlePdfExportClick = () => {
        if (GreatCircleRef && GreatCircleRef.current) {
            GreatCircleRef.current.generateGreatCiclePDF();
        }
    };
    // const printRef = useRef();

    const handleExcelExportClick = () => {
        if (GreatCircleRef && GreatCircleRef.current) {
            GreatCircleRef.current.generateGCExel();
        }
    };

    // const handlePrintClick = () => {
    //     window.print();
    // };

    const handlePrintClick = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div className="oneTable map-data">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "95%",
                    margin: "0 auto",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            width: "130px",
                            fontSize: "14px",
                            fontFamily: "Mukta",
                        }}
                        onClick={handlePdfExportClick}
                    >
                        EXPORT PDF
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            width: "130px",
                            fontSize: "14px",
                            fontFamily: "Mukta",
                        }}
                        onClick={handleExcelExportClick}
                    >
                        EXPORT EXCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            width: "130px",
                            fontSize: "14px",
                            fontFamily: "Mukta",
                        }}
                        onClick={handlePrintClick}
                    >
                        PRINT
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "100%",
                        paddingBottom: "10px",
                    }}
                >
                    {open && (
                        <>
                            <TextField
                                id="filled-basic"
                                label="Filled"
                                variant="filled"
                                onChange={handleEmailChange}
                                value={email}
                                error={!isValidEmail}
                                helperText={
                                    !isValidEmail
                                        ? "Enter a valid email address"
                                        : ""
                                }
                            />
                            <div>
                                <Button
                                    disabled={isValidEmail ? false : true}
                                    variant="contained"
                                    style={{
                                        width: "130px",
                                        fontSize: "14px",
                                        fontFamily: "Mukta",
                                    }}
                                    onClick={handleSendEmail}
                                >
                                    SEND
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    style={{
                                        width: "130px",
                                        fontSize: "14px",
                                        fontFamily: "Mukta",
                                    }}
                                    onClick={handleCancelEmail}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </>
                    )}
                    {!open && (
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                width: "130px",
                                fontSize: "14px",
                                fontFamily: "Mukta",
                            }}
                            onClick={handleEmail}
                        >
                            EMAIl
                        </Button>
                    )}
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    FLIGHT
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    TAIL#
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ACFT
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ORG
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    DEST
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ATDz
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ATAz
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                    onClick={() =>
                                        weightType === "LB"
                                            ? setWeightType("KG")
                                            : setWeightType("LB")
                                    }
                                    align="center"
                                >
                                    MTOW {weightType}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ATC COST(USD)
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "16px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    ROUTE
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {"FLX" + flightNumber.slice(3)}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {tailNumber || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {aircraftModel}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {departure}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {arrival}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {new Date(etd).toUTCString()}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    {new Date(eta).toUTCString()}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    <NumberFormat
                                        value={weight}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        suffix={` ${weightType}`}
                                    />
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    style={{
                                        fontSize: "15px",
                                        fontFamily: "Mukta",
                                    }}
                                >
                                    <NumberFormat
                                        value={totalCosts}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={"$ "}
                                    />
                                </StyledTableCell>
                                <FlightRouteTooltipCell route={legacy_format} />
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
